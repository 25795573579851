<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
        <section class="item-types">
          <breadcrumbs v-if="!modalView && !isEdit"></breadcrumbs>
          <h2 class="titlePage_subtitle titlePage">SELECT HINGE</h2>
          <span class="modal__close" v-if="modalView" @click="closePopup">
             <svg-icon name="times"/>
          </span>
          <div class="row">
            <div class="col-12">
              <p class="text">Hinge Type</p>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-12"
                 v-for="item in selection.groups.hinge_type.choices"
                 :key="item.id"
                 :class="{'col-lg-4': !checkIsSetPackage}"
            >
              <label class="form__label form__label_row">
                <input type="radio" hidden="hidden"
                       class="form__radio"
                       name="hinge_type"
                       v-bind:checked="item.default"
                       @change="selectOption(item)"
                >
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">{{item.name}}</span>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="text">Hinge Direction</p>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-12"
                 v-for="item in selection.groups.hinge_direction.choices"
                 :key="item.id"
                 :class="{'col-lg-4': !checkIsSetPackage}"
            >
              <label class="form__label form__label_row">
                <input type="radio" hidden="hidden"
                       class="form__radio"
                       name="hinge_direction"
                       @change="selectOption(item)"
                       v-bind:checked="item.default"
                >
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">{{item.name}}</span>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="text">Hinge Quantity</p>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-12"
                 v-for="item in selection.groups.hinge_qty.choices"
                 :key="item.id"
                 :class="{'col-lg-4': !checkIsSetPackage}"
            >
              <label class="form__label form__label_row">
                <input type="radio" hidden="hidden"
                       class="form__radio"
                       name="hinge_qty"
                       @change="selectOption(item)"
                       v-bind:checked="item.default"
                >
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">{{item.name}}</span>
              </label>
            </div>
          </div>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="button" class="btn btn_transparent" v-if="!isUpdate" @click.prevent="next"><svg-icon name="solid/step-forward" /> next</button>
              <button type="button" class="btn btn_transparent" v-else @click.prevent="next"><svg-icon name="solid/check" /> done</button>
            </portal>
          </template>
          <template v-else>
            <button type="button" class="btn btn_default" v-if="!isUpdate" @click.prevent="next"><svg-icon name="solid/step-forward" /> next</button>
            <button type="button" class="btn btn_default" v-else @click.prevent="next"><svg-icon name="solid/check" /> done</button>
          </template>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import breadcrumbs from "./../../parts/breadcrumbs";
  import {calcHingePriceType, calcHingePriceQty} from "../../../helpers/calcPriceOption";
  import {changePriceForm} from "../../../helpers/aroundPrice";
  import {mapState, mapGetters} from 'vuex';

  export default {
      name: "Hinges",
      data() {
          return {
              selection: {
              },

              modalView: false,
          }
      },
      components: {
          breadcrumbs
      },
      props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],

      methods: {
          selectOption(item, t) {
              const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);

              let target = t;

              if (event != null)
                  target = event.target.name;

              _.forEach(this.selection.groups[target].choices, (i, k) => {
                  i.default = item.name == i.name;
              });

              if(this.isEdit) {
                  // const backup = _.cloneDeep(this.selection)
                  const rollbackHandler = () => {
                      this.$store.commit('orderPosition/setOption', {
                          option: 'HNG',
                          selection: backup
                      });
                  }
                  this.$store.dispatch('orderPosition/trackChanges', rollbackHandler)
              }

              this.$store.commit('orderPosition/setOption', {option: 'HNG',
                  selection: this.selection});
          },

          next() {
              if(this.isEdit){
                  const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);
                  const rollbackHandler = () => {
                      this.$store.commit('orderPosition/setOption', {
                          option: 'HNG',
                          selection: backup
                      });
                  }
                  this.$store.dispatch('orderPosition/guessNextStep', rollbackHandler)
              }else {
                  this.$store.commit('orderPosition/optionNext', 'HNG');
              }
          },

          checkDefault() {
              // _.forEach(this.selection.groups.hinge_type.choices, (i) => {
              //     if (i.default == true)
              //         this.selectOption(i, 'hinge_type');
              // });
              //
              // _.forEach(this.selection.groups.hinge_qty.choices, (i) => {
              //     if (i.default == true)
              //         this.selectOption(i, 'hinge_qty');
              // });
              //
              // _.forEach(this.selection.groups.hinge_direction.choices, (i) => {
              //     if (i.default == true)
              //         this.selectOption(i, 'hinge_direction');
              // });
         },


          closePopup() {
              this.$store.commit('options/closeAvailable');
          },
      },
      computed: {
          ...mapState('orderItem', ['isUpdate']),
          ...mapGetters({
              isEdit: 'orderPosition/isCompleted',
          })

      },
          created() {
              this.selection = this.$store.getters['orderPosition/getCurrentOptionSettings'];
              setTimeout(() => {
                  this.checkDefault();
              }, 500)
          },

          beforeUpdate() {
          }
  }
</script>

<style scoped lang="scss">

  @import "../../../assets/scss/utils/vars";

  .form {
    &__label {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }

    &__labelTitle {
      color: $navy;
    }
  }

  .text_white {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
  }

  .modal__close {
    color: $green;
  }

</style>

