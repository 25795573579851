"use strict";
export function changePriceForm (price) {
  if(price !== undefined){
      const val = (price/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
    return '0.00';
  }
}
