<template>
  <ul v-if="breadcrumbs" class="breadcrumbs ct">
    <li v-for="(item, i) in getNameAvailableBreadcrumbs"
        :key="i"
        class="breadcrumbs__item"
        :class="{'breadcrumbs__item_disabled': item.disabled}"
        @click.prevent="navigate(item, i)"
    >
      <a v-if="!item.disabled">
        {{ item.name }}
        <span v-if="item.selected">({{Object.keys(item.selected).length}})</span>
      </a>
      <span v-else class="disabled">
        {{ item.name }}
        <span v-if="item.selected">({{Object.keys(item.selected).length}})</span>
      </span>
      <ul class="breadcrumbs__drop " v-if="item.selected">
        <li class="breadcrumbs__dropItem" v-for="el in item.selected" :key="el.id">{{el.name}}</li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import router from "../../router";
  import {mapMutations} from 'vuex';
  import {eventBus} from "../../main";

  export default {
    name: "breadcrumbs",
    data() {
      return {
        bar: '',
      }
    },
    created() {
      eventBus.on('go-back-breadcrumbs', data => {
        this.navigate(data.breadcrumb, data.index)
      });
    },
    methods: {
      ...mapMutations('app', ['setPrevBreadcrumbs']),
      navigate(o, i) {
        let bs = this.breadcrumbs;
        if (o.disabled) {
          return false;
        }
        this.$store.commit('app/set', {k: 'breadcrumbs', v: _.slice(this.breadcrumbs, 0, i)});

        this.$store.commit('orderPosition/forceStep', o);

       },
    },
    computed: {
      breadcrumbs() {
        return this.$store.getters['app/get']('breadcrumbs');
      },

      createOrder() {
        return this.$store.getters['app/createOrder'];
      },

      editSingleOptions(){
        return this.$store.getters['options/editSingleOptions']
      },

      totalItems() {
        return Object.keys(this.breadcrumbs).length;
      },
      getNameAvailableBreadcrumbs(){
        return _.filter(this.breadcrumbs, (item, i)=>{
          return item.name
        })
      }
    },
  }
</script>

<style scoped>
  ul.breadcrumbs{
    padding-top: 25px;
  }
</style>
